import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggler", "menu", "openIcon", "closeIcon"]
  static classes = ["inactive"]

  toggle() {
    const willOpen = this.togglerTarget.getAttribute('aria-expanded') !== 'true'

    if (willOpen) {
      this.togglerTarget.setAttribute('aria-expanded', 'true')
      this.openIconTarget.classList.add(this.inactiveClass)
      this.closeIconTarget.classList.remove(this.inactiveClass)
      this.menuTarget.classList.remove(this.inactiveClass)
    } else {
      this.togglerTarget.setAttribute('aria-expanded', 'false')
      this.openIconTarget.classList.remove(this.inactiveClass)
      this.closeIconTarget.classList.add(this.inactiveClass)
      this.menuTarget.classList.add(this.inactiveClass)
    }
  }
}
